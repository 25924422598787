import React, { useEffect } from 'react';

const Privacy = () => {
  useEffect(() => {
    window.location.href = "https://www.iubenda.com/privacy-policy/272274/full-legal";
  }, []);

  return null; 
};

export default Privacy;