import { Button } from "@nextui-org/react";
import React, { useState, useEffect } from "react";
import CashKarmaLogo from "../../../component/Icons/CashKarma/CashKarmaLogo.js";
import SuccessConfirmationIcon from "../../../component/Icons/CashKarma/SuccessConfirmationIcon.js";

import * as UnsubscribeRequest from "../../../http_request/UnsubscribeRequest.js";
import * as UrlUtil from "../../../util/UrlUtil.js";
import * as ConfigUtil from "../../../util/ConfigUtil.js";

export default function EmailUnsubscribedConfirmation() {

    //const [loading, setLoading] = useState(true);
    const [isSuccessResponse, setIsSuccessResponse] = useState(false);
    const [responseMsg, setResponseMsg] = useState(null);

    useEffect(() => {
      let locObj = UrlUtil.getLocationUrlData();
      let hasVal = locObj["hash"];
      UnsubscribeRequest.unsubscribeAccount(hasVal,
        (errorInfo) => {
          if (errorInfo) {
            //setLoading(false);
            setIsSuccessResponse(false);
            if (errorInfo["errorMsg"]) {
              setResponseMsg(errorInfo["errorMsg"]);
            } else {
              let fallbackMsg = "Something went wrong.";
              setResponseMsg(fallbackMsg);
            }
            //ToastUtil.showToastReqError(errorInfo)
          } else {
            //setLoading(false);
            setIsSuccessResponse(true);
            setResponseMsg("You have been unsubscribed successfully");
          }
      });
    }, []);
  return (
    <div
      className="h-screen flex flex-col items-center justify-center overflow-scroll"
      style={{ backgroundColor: "#FFF6EB", fontFamily: "Fira Sans, sans-serif" }}>
      <div className="mb-4">
        <CashKarmaLogo />
      </div>
      <div
        className="lg:w-1/3 md:w-1/2 w-96 flex items-center justify-center p-4 rounded-lg"
        style={{ backgroundColor: "#FFFFFF", border: '2px solid #FF7400', borderRadius: '50px',
                display: (responseMsg != null && isSuccessResponse) ? "block": "none"
        }}>
        <div className="rounded-md p-2 w-full">
          <div className="flex justify-center">
            <div>
              <SuccessConfirmationIcon />
            </div>
          </div>
          <div className="text-center p-4 text-white">
            <div className="text-[#212121] text-2xl" style={{ fontFamily: 'FiraSans-ExtraBold'}}>Email Unsubscribed</div>
            <div className="text-[#424242] text-sm mt-4" style={{ fontFamily: 'Montserrat-Regular' }}>
              {responseMsg}
            </div>
          </div>
          <div className="px-3">
            <a href="https://bnc.lt/open-cashkarma-from-web" style={{ textDecoration: 'none' }}>
              <Button
                className="w-full mt-2 mb-4 text-[#FFFFFF]"
                style={{ backgroundColor: "#FF7400", fontFamily: 'FiraSans-Bold', borderRadius: '50px' }}
                onClick={() => {
                  let linkUrl = ConfigUtil.getBranchAppLink();
                  UrlUtil.openUrl2(linkUrl);
                }}
                >
              Open CashKarma
            </Button>
            </a>
          </div>
        </div>
      </div>
      {ErrorView(responseMsg, isSuccessResponse)}
    </div>
  );
}

function ErrorView(responseMsg, isSuccessResponse) {
  return <div
          className="lg:w-1/3 md:w-1/2 w-96 flex items-center justify-center p-4 rounded-lg"
          style={{ backgroundColor: "#FFF6EB" ,
                  display: (responseMsg != null && isSuccessResponse === false) ? "block": "none"
          }}>
  <div className="rounded-md p-2 w-full">
    <div className="text-center p-4 text-white">
      <div className="text-[#EF5350] text-2xl" style={{ fontFamily: 'FiraSans-ExtraBold'}}>Unsubscribe Failed!</div>
      <div className="text-[#424242] text-sm mt-4" style={{ fontFamily: 'Montserrat-Regular' }}>
        {responseMsg}
      </div>
    </div>
  </div>
</div>

}