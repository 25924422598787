import { Button, Input } from "@nextui-org/react";
import React, { useState } from "react";
import { EyeSlashFilledIcon } from "../../../component/Icons/EyeSlashFilledIcon";
import { EyeFilledIcon } from "../../../component/Icons/EyeFilledIcon";
import CashKarmaLogo from "../../../component/Icons/CashKarma/CashKarmaLogo";
import { useNavigate } from "react-router-dom";
import * as UrlUtil from "../../../util/UrlUtil.js";
import * as PasswordResetChangeRequest from "../../../http_request/PasswordResetChangeRequest.js";
import * as ToastUtil from "../../../util/ToastUtil.js";

function ResetPassword() {
  const [isVisible, setIsVisible] = useState(false);
  const [isInvalid, setIsInvalid] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();

  const [resetPassword, setResetPassword] = useState({
    password: "",
    confirmPassword: "",
  });

  const [loading, setLoading] = useState(false);

  const toggleVisibility = () => setIsVisible(!isVisible);

  const validatePassword = (password) => {
    let error = "";
    if (password.includes(" ")) {
      error = "Password should not contain spaces.";
    } else if (password.length < 6) {
      error = "Password must be at least 6 characters long.";
    }
    return error;
  };

  const handleSubmit = () => {
    if (resetPassword.password !== resetPassword.confirmPassword) {
      setIsInvalid({ ...isInvalid, confirmPassword: true });
      setErrorMessage({ ...errorMessage, confirmPassword: "Passwords do not match." });
    } else {
      setLoading(true);
      let locObj = UrlUtil.getLocationUrlData();
      let hasVal = locObj["hash"];

      PasswordResetChangeRequest.changeResetPassword(hasVal, resetPassword.password, 
        (errorInfo) => {
          if (errorInfo) {
            setLoading(false);
            ToastUtil.showToastReqError(errorInfo)
          } else {
            setLoading(false);
            navigate("/cashkarma/account/reset-password-confirmation");
            setIsInvalid({ password: false, confirmPassword: false });
            setErrorMessage({ password: "", confirmPassword: "" });
          }
      });
      }
  };

  const handlePasswordChange = (e) => {
    const password = e.target.value;
    const error = validatePassword(password);
    setResetPassword({ ...resetPassword, password });
    setIsInvalid({ ...isInvalid, password: !!error });
    setErrorMessage({ ...errorMessage, password: error });
  };

  const handleConfirmPasswordChange = (e) => {
    const confirmPassword = e.target.value;
    setResetPassword({ ...resetPassword, confirmPassword });
    setIsInvalid({ ...isInvalid, confirmPassword: (resetPassword.password !== confirmPassword) });
    setErrorMessage({ ...errorMessage, confirmPassword: (resetPassword.password !== confirmPassword) ? "Passwords do not match." : "" });
  };

  const handleFocus = () => {
    setIsInvalid({ password: false, confirmPassword: false });
    setErrorMessage({ password: "", confirmPassword: "" });
  };
  

  return (
    <div className="h-screen flex flex-col items-center justify-center overflow-scroll"
         style={{ backgroundColor: "#FFF6EB", fontFamily: "Fira Sans, sans-serif" }}>
      <div className="mb-4">
        <CashKarmaLogo />
      </div>
      <div className="lg:w-1/3 md:w-1/2 w-96 flex items-center justify-center p-4 rounded-lg"
           style={{ backgroundColor: "#FFFFFF", border: '2px solid #FF7400', borderRadius: '20px' }}>
        <div className="w-full">
          <div className="text-center p-4 text-white mb-12">
            <div className="text-[#212121] text-2xl" style={{ fontFamily: 'FiraSans-ExtraBold' }}>Reset Your Password</div>
            {/* <div className="text-[#424242] text-sm mt-1" style={{ fontFamily: 'Montserrat-Regular' }}>Enter New Password</div> */}
          </div>

          <Input
            style={{ fontFamily: 'Montserrat-Regular' }}
            className={{ }}
              classNames={{
                input: [
                  "bg-transparent",
                  "placeholder:text-default-100/25",
                  "text-white/90 dark:text-white/90",
                  "group-data-[has-value=true]:text-[#333333]"
                ],
                mainWrapper: [
                ],
                innerWrapper: [
                  "bg-transparent",
                ],
                inputWrapper: [
                  "border",
                  "shadow-sm",
                  "bg-[#FFFFFF]",
                  "focus-within:!bg-[#FFFFFF]",
                  "focus-within:border-[#FFD1AA]",
                  "border-[#EEEEEE]", 
                  "!cursor-text",
                  "caret-[#000000]"
                ],
              }}
            placeholder="Enter new password"
            label={<span className="text-[#424242]">New Password</span>}
            labelPlacement="outside"
            radius="sm"
            endContent={
              <button
                className="focus:outline-none"
                type="button"
                onClick={toggleVisibility}
              >
                {isVisible ? (
                  <EyeSlashFilledIcon className="text-2xl text-default-400 pointer-events-none" />
                ) : (
                  <EyeFilledIcon className="text-2xl text-default-400 pointer-events-none" />
                )}
              </button>
            }
            type={isVisible ? "text" : "password"}
            color={isInvalid.password ? "error" : "default"}
            errorMessage={errorMessage.password}
            defaultValue={resetPassword.password}
            onChange={handlePasswordChange}
            onFocus={handleFocus}
          />

          <Input
            style={{ fontFamily: 'Montserrat-Regular' }}
            className="pt-4 pb-8"
              classNames={{
                input: [
                  "bg-transparent",
                  "placeholder:text-default-100/25",
                  "text-white/90 dark:text-white/90",
                  "group-data-[has-value=true]:text-[#333333]"
                ],
                mainWrapper: [
                ],
                innerWrapper: [
                  "bg-transparent",
                ],
                inputWrapper: [
                  "border",
                  "shadow-sm",
                  "bg-[#FFFFFF]",
                  "focus-within:!bg-[#FFFFFF]",
                  "focus-within:border-[#FFD1AA]",
                  "border-[#EEEEEE]", 
                  "!cursor-text",
                  "caret-[#000000]"
                ],
              }}
            placeholder="Re-type password"
            label={<span className="text-[#424242]">Confirm new password</span>}
            labelPlacement="outside"
            radius="sm"
            endContent={
              <button
                className="focus:outline-none"
                type="button"
                onClick={toggleVisibility}
              >
                {isVisible ? (
                  <EyeSlashFilledIcon className="text-2xl text-default-400 pointer-events-none" />
                ) : (
                  <EyeFilledIcon className="text-2xl text-default-400 pointer-events-none" />
                )}
              </button>
            }
            type={isVisible ? "text" : "password"}
            color={isInvalid.confirmPassword ? "error" : "default"}
            errorMessage={errorMessage.confirmPassword}
            defaultValue={resetPassword.confirmPassword}
            onChange={handleConfirmPasswordChange}
            onFocus={handleFocus}
          />

          <Button
            className="w-full mb-4 text-[#FFFFFF]"
            style={{ backgroundColor: "#FF7400", fontFamily: 'FiraSans-Bold', borderRadius: '50px' }}
            isLoading={loading}
            onClick={() => {
              handleSubmit();
            }}
          >
           Submit 
          </Button>
        </div>
      </div>
    </div>
  );
}

export default ResetPassword;
