import React from 'react'

export default function Terms() {

  return (
    <div className='lg:px-10 px-2 pt-8 mb-16 h-screen bg-white-400 flex justify-center flex-row container mx-auto w-screen'>
      <div className='mt-16 w-full md:px-0 px-4'>
        <h1 className='px-5 py-4 bg-[#FAFAFA] lg:text-3xl text-2xl text-center' style={{ fontFamily: 'Plus Jakarta Sans',fontWeight: '800'}}>Terms of Service</h1>
        <div>
          <p className='py-12 text-[#888888] text-sm' style={{ fontFamily: 'Plus Jakarta Sans',fontWeight: '600' }}>LAST UPDATED: JAN 15, 2025</p>
        </div>
        <div className='pb-24'>
          <h1 className='lg:text-2xl text-xl' style={{ fontFamily: 'Plus Jakarta Sans',fontWeight: '800' }}>Introduction</h1>
          <p className='mt-4'>These Terms of Service ("Terms") govern your access to and use of the mobile applications, 
                              websites, and services (collectively, "Services") provided by Darkfield Software LLC, doing business 
                              as MomoKarma ("MomoKarma," "we," "us," or "our"). By accessing or using our Services, you 
                              agree to be bound by these Terms and our Privacy Policy. Please refer to our <a href="https://www.momokarma.com/legal/privacy" 
                              target="_blank" rel="noopener noreferrer" className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600">Privacy Policy</a> for detailed 
                              information on how we handle your data, including data protection and security measures.</p>
          
          <h1 className='mt-12 lg:text-2xl text-xl' style={{ fontFamily: 'Plus Jakarta Sans',fontWeight: '800' }}>Acceptance of Terms</h1>
          <p className='mt-4'>By registering for, accessing, or using our Services, you confirm your acceptance of these Terms and 
                              agree to comply with our <a href="https://www.momokarma.com/legal/privacy" 
                              target="_blank" rel="noopener noreferrer" className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600">Privacy Policy</a>. If you do not 
                              agree to these Terms, you must not use our Services.</p>
          
          <h1 className='mt-12 lg:text-2xl text-xl' style={{ fontFamily: 'Plus Jakarta Sans',fontWeight: '800' }}>Eligibility</h1>
          <p className='mt-4'>To use our Services, you must be at least 18 years old and reside in a country where our Services are available. 
                              If you do not meet these requirements, you are prohibited from using or accessing our Services.</p>

          <h1 className='mt-12 lg:text-2xl text-xl' style={{ fontFamily: 'Plus Jakarta Sans',fontWeight: '800' }}>Changes to Terms</h1>
          <p className='mt-4'>We may modify these Terms occasionally to reflect changes in our Services, address the evolving needs of our users, 
                              or adjust to our business priorities. Any changes will be effective immediately upon posting of the revised Terms. 
                              By continuing to use our Services after these changes are posted, you agree to the revised Terms. If you do not
                              agree to the changes, you must stop using and accessing our Services immediately.</p>

          <h1 className='mt-12 lg:text-2xl text-xl' style={{ fontFamily: 'Plus Jakarta Sans',fontWeight: '800' }}>Service Details</h1>
          <div>
            <ul className='list-disc md:ps-12 px-8 py-4'>
                <li className='py-2'>
                  MomoKarma develops applications that allow users to earn virtual currencies, referred to as Karma Points, which can be 
                  exchanged for rewards. These virtual currencies hold no real-world monetary value, and any balance in user accounts 
                  remains the property of MomoKarma.
                </li>
                <li className='py-2'>
                  Users accumulate rewards by completing offers or tasks specified within the application. An offer is deemed successfully
                  completed when all its specified requirements are fulfilled.
                </li>
                <li className='py-2'>
                  The operation of the Service, including fraud detection and the ability to track reward activities to generate income 
                  for funding the rewards, necessitates our collection, storage, and transmission of device identifiers and user 
                  information. Certain features, related to Location Rewards and Playtime Rewards, will require the collection and transmission of location and app 
                  usage data to function. For detailed information on how these Third Parties handle your data, please refer 
                  to our <a href="https://www.momokarma.com/legal/privacy" target="_blank" rel="noopener noreferrer" className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600">
                  Privacy Policy</a>.
                </li>
                <li className='py-2'>
                  The issuance of rewards is contingent upon MomoKarma and/or its partners’ ability to accurately track activities; 
                  therefore, no guarantee of rewards is provided even if all offer requirements are met. Moreover, offer requirements
                  may occasionally be subject to inaccuracies due to technical or administrative errors; hence, MomoKarma will not 
                  be held liable for any missing rewards due to such discrepancies.
                </li>
                <li className='py-2'>
                  Users can redeem accumulated points for rewards within the app, such as cash payments (via PayPal or prepaid cash
                  cards) or gift cards. Users are responsible for any tax liabilities arising from the use of the Service.
                </li>
                <li className='py-2'>
                  MomoKarma is not liable or obligated to issue rewards for any offer that is not properly tracked and verified 
                  as successfully completed. In certain instances, the determination of whether an offer has been successfully 
                  completed may be made by third-party partners.
                </li>
                <li className='py-2'>
                  As our Service is subject to continuous evolution, features may change over time, and we may discontinue the 
                  Service or any features within the Service at our discretion. We also reserve the right to impose limits on 
                  use and rewards at any time without prior notice.
                </li>
                <li className='py-2'>
                  In the event an account is suspended, deleted, or if an app ceases operations, all unused balances in the 
                  account will be forfeited.
                </li>
                <li className='py-2'>
                  We reserve the right to withdraw or amend our Service, and any service or material we provide via the
                  Service, at our sole discretion without notice. MomoKarma will not be liable if, for any reason, all or any 
                  part of the Service is unavailable at any time or for any period. We do not guarantee that all Services will 
                  be available at all times, in all locations, due to technical, regulatory, and other restrictions.
                </li>
            </ul>
          </div>
          <h1 className='mt-6 lg:text-2xl text-xl' style={{ fontFamily: 'Plus Jakarta Sans',fontWeight: '800' }}>Third Party Software</h1>
          <p className='mt-4'>As part of our Services, we may incorporate or provide access to software, services, or links 
                              developed by external providers, specifically advertising services and survey providers. These 
                              Third Party Software offer you opportunities to complete offers or participate in surveys in 
                              exchange for rewards. While MomoKarma facilitates access to these opportunities, we do not 
                              control the Third Party Software or their functionalities.</p>
          <p className='mt-4'>We expressly disclaim any liability for your use of Third Party Software and for any resulting 
                              damages, whether direct, indirect, incidental, or otherwise. This limitation of liability applies 
                              even if we have been informed of the potential for such damages. It is important to note that we 
                              operate independently from these Third Parties in terms of data processing related to our Services.</p>
          <p className='mt-4'>Your interactions with Third Party Software, including any personal data you share or compliance with 
                              their terms, are managed solely between you and the external providers. You are responsible for 
                              reviewing and adhering to the terms and privacy policies of these Third Parties. We encourage you to 
                              be cautious with the personal information you provide, particularly in surveys. For detailed 
                              information on how these Third Parties handle your data, please refer to our  <a href="https://www.momokarma.com/legal/privacy" 
                              target="_blank" rel="noopener noreferrer" className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600">Privacy Policy</a>, which 
                              includes the privacy policies of our third-party partners. </p>
          <p className='mt-4'>Should you encounter any issues or have concerns regarding Third Party Software, please <a href="mailto:support@momokarma.com" 
                              className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600">contact us</a>. This helps us to maintain 
                              a secure and trustworthy environment for all users.</p>

          <h1 className='mt-12 lg:text-2xl text-xl' style={{ fontFamily: 'Plus Jakarta Sans',fontWeight: '800' }}>Prohibited Actions</h1>
          <p className='mt-4'>In order to maintain the integrity and functionality of our Services, you must adhere to all policies
                              provided within the Service. You are permitted to use our Services only in accordance with applicable 
                              laws and these Terms. Failure to comply with our Terms or policies, or if there is suspected misconduct, 
                              may result in suspension, blocking of reward redemption, or discontinuation of our Services.</p>
          <ul className='list-disc md:ps-12 px-8 py-4'>
            <li className='py-2'>Do not post, submit, or transmit any User Content that poses a direct, specific threat of violence against others.</li>
            <li className='py-2'>Do not use the Service for unlawful purposes or to promote illegal activities.</li>
            <li className='py-2'>Do not impersonate others in a manner intended to mislead, confuse, or deceive.</li>
            <li className='py-2'>Do not access, tamper with, or use non-public areas of our Service, our backend systems, or our providers' technical delivery systems.</li>
            <li className='py-2'>Do not probe, scan, or test the vulnerability of our systems or networks, or breach security or authentication measures.</li>
            <li className='py-2'>Do not falsify your identity, location, or create multiple accounts by modifying or resetting your device IDs, or changing your IP address (e.g., using VPNs/Proxies).</li>
            <li className='py-2'>Do not create “fake” accounts to earn referral rewards.</li>
            <li className='py-2'>Do not attempt to create more than one account per device. Each account may only be associated with a certain number of devices within a specified timeframe.</li>
            <li className='py-2'>Do not use fraudulent or unnatural methods to complete offers for rewards, including making purchases repeatedly and issuing chargebacks or requesting refunds.</li>
            <li className='py-2'>Do not automate views, clicks, or conversions.</li>
            <li className='py-2'>Do not use emulators or rooted devices to access our Services.</li>
            <li className='py-2'>Do not use software to modify games or unlock in-game content that otherwise requires an in-app purchase.</li>
            <li className='py-2'>Do not circumvent any technological measures implemented to protect the Service, track reward issuance, or protect any content on the Service.</li>
            <li className='py-2'>Do not use automated software to bypass the application to earn rewards (e.g., issuing requests that do not originate from our app).</li>
            <li className='py-2'>Do not access, search, or download content from the Service using any tool other than those provided by us or generally available third-party web browsers.</li>
            <li className='py-2'>Do not send unsolicited or unauthorized advertising, promotional materials, spam, or any other form of solicitation.</li>
            <li className='py-2'>Do not interfere with or disrupt access to our Service by other users, hosts, or networks, including distributing viruses or other harmful material.</li>
            <li className='py-2'>Do not collect or store any personally identifiable information from the Service from other users without their express permission.</li>
            <li className='py-2'>Do not attempt to deceive the Company to obtain additional virtual currency or gift cards.</li>
            <li className='py-2'>Do not encourage or enable others to engage in any of the prohibited activities listed above.</li>
          </ul>
          <p className='mt-2'>By using our Services, you agree to abide by these prohibitions to ensure a secure and reliable experience for all users.</p>

          <h1 className='mt-12 lg:text-2xl text-xl' style={{ fontFamily: 'Plus Jakarta Sans',fontWeight: '800' }}>Termination</h1>
          <p className='mt-4'>MomoKarma reserves the right to terminate or suspend your access to our Services immediately, without prior notice or liability, for any reason, 
                              including but not limited to a breach of these Terms. Upon termination, your right to use the Services will immediately cease.</p>
          <p className='mt-4'>Additionally, we may terminate any account that has been inactive for a period of 12 months. An account is considered inactive if there has been 
                              no account access and no points have been earned during this period.</p>
                              <h1 className='mt-12 lg:text-2xl text-xl' style={{ fontFamily: 'Plus Jakarta Sans',fontWeight: '800' }}>Intellectual Property</h1>
          <p className='mt-4'>
            The Services, including all underlying technology, software, original content, features, functionality, and any developments, updates, or derivatives thereof, 
            are owned by and shall remain the exclusive property of MomoKarma. This encompasses all trademarks, trade dress, logos, and other proprietary marks owned by 
            MomoKarma. These assets may not be used in connection with any product or service not affiliated with MomoKarma, nor in any manner that is likely to cause 
            confusion among customers, or in any manner that disparages or discredits MomoKarma.
          </p>
          <p className='mt-4'>
            Certain components of the Services may be the property of third parties and used under license. These third-party trademarks and copyrights are the property 
            of their respective owners, who may or may not be affiliated with, connected to, or sponsored by MomoKarma.
          </p>
          <p className='mt-4'>
            You are prohibited from copying, modifying, reverse engineering, or otherwise attempting to discover any source code of the Services. Additionally, you may 
            not sell, assign, sublicense, or otherwise transfer any right in the Services without the express written consent of MomoKarma.
          </p>
          <p className='mt-4'>
            All other trademarks not owned by MomoKarma that appear in connection with the Services are the property of their respective owners, who may or may not be
            affiliated with, connected to, or sponsored by MomoKarma.
          </p>

          <h1 className='mt-12 lg:text-2xl text-xl' style={{ fontFamily: 'Plus Jakarta Sans',fontWeight: '800' }}>Disclaimer of Warranty</h1>
          <p className='mt-4'>
            MomoKarma strives to maintain the Services safe and free from bugs; however, you acknowledge that you use the Services at your own risk. The Services are 
            provided on an "as is" and "as available" basis without any warranties of any kind, either express or implied. MomoKarma does not guarantee that the 
            Services will be uninterrupted, accurate, error-free, or free of viruses or other harmful components that may infect your devices, software, data, or networks.
          </p>
          <p className='mt-4'>
            You are responsible for implementing adequate measures and controls to meet your specific security and data integrity needs. It is crucial to understand 
            that while MomoKarma takes reasonable steps to maintain the security of its Services, the effectiveness of these measures can also depend heavily on the 
            user's actions. Therefore, you are advised to ensure robust security practices such as maintaining up-to-date antivirus software, using secure passwords, 
            and regularly backing up your data.
          </p>
          <p className='mt-4'>
            Furthermore, MomoKarma does not warrant that files available for downloading from the internet or the website will be free of viruses or other destructive 
            code. You should implement sufficient procedures and checkpoints to satisfy your particular requirements for anti-virus protection and data accuracy, 
            and to maintain external means for the reconstruction of any lost data.
          </p>
          <p className='mt-4'>
            MomoKarma will not be liable for any loss or damage caused by a distributed denial-of-service attack, viruses, or other technologically harmful material 
            that may infect your computer equipment, programs, data, or other proprietary material due to your use of the Services or any services or items obtained 
            through the Services, including downloading any material posted on it or on any linked website.
          </p>
          <p className='mt-4'>
            Your use of the Services, their content, and any items obtained through the Services is solely at your own risk. MomoKarma and its associates make no 
            representations, warranties, or guarantees regarding the completeness, security, reliability, quality, accuracy, or availability of the Services. 
            Moreover, MomoKarma expressly disclaims all warranties, whether express or implied, statutory or otherwise, including but not limited to any warranties 
            of merchantability, non-infringement, and fitness for a particular purpose, except for those warranties which cannot be excluded or limited under applicable law.
          </p>

          <h1 className='mt-12 lg:text-2xl text-xl' style={{ fontFamily: 'Plus Jakarta Sans',fontWeight: '800' }}>Limitations on Liability</h1>
          <p className='mt-4'>
            To the fullest extent permitted by applicable law, MomoKarma, its affiliates, and their respective licensors, service providers, employees, agents, officers, 
            and directors will not be liable for any damages or losses of any kind arising from your use or inability to use our applications, websites, services, or 
            any linked websites. This includes not only financial losses but also more extensive damages such as personal injury, emotional distress, loss of business 
            or potential earnings, which may arise whether due to negligence, breach of contract, or other causes, even if such damages were foreseeable.
          </p>
          <p className='mt-4'>
            Specifically, you are responsible for:
            <ul className='list-disc md:ps-12 px-8 py-4'>
              <li className='py-2'>Ensuring the security and integrity of the way you use our Services.</li>
              <li className='py-2'>Complying with the terms and guidelines for safe and intended use of our Services.</li>
              <li className='py-2'>Using our Services within the bounds of the law and not for any unlawful activities.</li>
            </ul>
            In legal terms, we limit liability for direct damages like financial losses and indirect damages such as lost potential earnings ("consequential damages") 
            or emotional impacts. Here’s what these terms mean:
            <ul className='list-disc md:ps-12 px-8 py-4'>
              <li className='py-2'>Direct damages: Immediate financial losses directly traceable to our Services.</li>
              <li className='py-2'>Indirect or consequential damages: Losses or damages not directly traceable to our Services, like lost profits or emotional distress.</li>
            </ul>
            MomoKarma will not be held liable for any termination of user access to the Services or for the deletion of user data.
            These limitations apply unless prohibited by law and do not affect any liabilities that cannot be legally excluded or limited.
          </p>

          <h1 className='mt-12 lg:text-2xl text-xl' style={{ fontFamily: 'Plus Jakarta Sans',fontWeight: '800' }}>Indemnification</h1>
          <p className='mt-4'>
            You agree to indemnify, defend, and hold harmless MomoKarma, its affiliates, licensors, service providers, and their respective officers, directors, employees, 
            contractors, agents, licensors, suppliers, and successors from and against any claims, liabilities, damages, judgments, awards, losses, costs, expenses, 
            or fees (including reasonable attorneys' fees) arising out of or relating to your violation of these Terms of Service or your use of the Services. This 
            obligation includes, but is not limited to, claims arising from your actions such as unauthorized content distribution, misuse of the Services, or your use 
            of any information obtained from the Services not expressly authorized in these Terms.
          </p>
          <p className='mt-4'>
            MomoKarma retains the right to assume the exclusive defense and control of any matter subject to indemnification by you, and you will not in any case 
            settle any claim without the prior written consent of MomoKarma. You are required to cooperate with our defense of these claims.
            Should you become aware of any issue or claim that could involve indemnification under these Terms, you must promptly notify MomoKarma in 
            writing. Upon receiving such notification, MomoKarma will provide you with instructions on how to proceed with cooperating in the defense of the claim. 
            It is important not to admit liability or attempt to settle any claim that might involve indemnification without the consent of MomoKarma.
          </p>

          <h1 className='mt-12 lg:text-2xl text-xl' style={{ fontFamily: 'Plus Jakarta Sans',fontWeight: '800' }}>Governing Law</h1>
          <p className='mt-4'>
            These Terms of Service and any separate agreements whereby we provide you Services shall be governed by and construed in accordance with the laws of 
            the State of California, United States, without regard to its conflict of law principles. The use of our Services is unauthorized in any jurisdiction 
            that does not give full effect to all provisions of these Terms, including this section. We do not represent that the Services are appropriate or 
            available for use in locations outside of the United States. If you access the Services from outside the United States, you are responsible for 
            compliance with local laws.
          </p>

          <h1 className='mt-12 lg:text-2xl text-xl' style={{ fontFamily: 'Plus Jakarta Sans',fontWeight: '800' }}>Arbitration Agreement</h1>
          <p className='mt-4'>
            MomoKarma requires the use of arbitration on an individual basis to resolve disputes, rather than jury trials or class actions, and limits the 
            remedies available to you in the event of a dispute as described in these Terms of Service.
          </p> 
          <p className='mt-4'>Any claim or dispute between you and MomoKarma 
            arising out of or relating to this agreement, or the use of any product or service provided by MomoKarma, regardless of the date of accrual 
            of such dispute, shall be resolved exclusively by binding arbitration administered by the American Arbitration Association (AAA) under its 
            Consumer Arbitration Rules. This arbitration agreement is made pursuant to a transaction involving interstate commerce and shall be governed by 
            the Federal Arbitration Act (9 U.S.C. §§ 1-16).
          </p> 
          <p className='mt-4'> 
            Arbitration will be conducted by a single neutral arbitrator and will take place in the United States 
            at a location that is reasonably convenient for both parties, with due consideration of their ability to travel and other pertinent circumstances. If 
            the parties are unable to agree on a location, the determination shall be made by the AAA.
          </p>
          <p className='mt-4'> Each party will bear their own costs and fees related to the arbitration proceedings, including attorney fees and expenses, unless 
            otherwise determined by the arbitrator under the applicable AAA rules or applicable law. You agree that any arbitration or proceeding shall be limited 
            to the dispute between us and you individually. To the full extent permitted by law, (1) no arbitration shall be joined with any other proceeding; 
            (2) there is no right or authority for any dispute to be arbitrated on a class-action basis or to utilize class action procedures; and (3) there is no 
            right or authority for any dispute to be brought in a purported representative capacity on behalf of the general public or any other persons.
          </p>
          <p className='mt-4'> If any part of this Arbitration Agreement is found under the law to be invalid or unenforceable, then that specific 
            part shall be of no force and effect and shall be severed, and the remainder of the Agreement shall continue in full force and effect. Any or all of the 
            rights and limitations set forth in this Arbitration Agreement may be waived by the party against whom the claim is asserted. Such a waiver shall not waive 
            or affect any other portion of this Arbitration Agreement.
          </p>

          <h1 className='mt-12 lg:text-2xl text-xl' style={{ fontFamily: 'Plus Jakarta Sans',fontWeight: '800' }}>Contact Us</h1>
          <p className='mt-4'>
            If you have any questions about these Terms, please <a href="mailto:support@momokarma.com" className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600">contact us</a>.
          </p>                    
        </div>
      </div>
    </div>
  )
}
