import React from "react";

const SuccessConfirmationIcon = () => {
  return (
    <svg
    width="60px"
    height="60px"
    viewBox="0 0 140 140"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>{"icons8-confirm (1)"}</title>
    <g
      id="MintKarma-Assets"
      stroke="none"
      strokeWidth={1}
      fill="none"
      fillRule="evenodd"
    >
      <g id="icons8-confirm-(1)" fillRule="nonzero">
        <circle id="Oval" fill="#ECEFF1" cx={70} cy={70} r={63} />
        <path
          d="M70,140 C31.402,140 0,108.598 0,70 C0,31.402 31.402,0 70,0 C108.598,0 140,31.402 140,70 C140,108.598 108.598,140 70,140 Z M70,14 C39.123,14 14,39.123 14,70 C14,100.877 39.123,126 70,126 C100.877,126 126,100.877 126,70 C126,39.123 100.877,14 70,14 Z"
          id="Shape"
          fill="#2A533B"
        />
        <circle id="Oval" fill="#54A675" cx={70} cy={70} r={42} />
        <polygon
          id="Path"
          fill="#ECEFF1"
          points="66.9375 93.9015 44.4885 71.449 54.3865 61.551 66.9375 74.0985 103.768 37.268 113.6695 47.1695"
        />
      </g>
    </g>
  </svg>
  );
};

export default SuccessConfirmationIcon;
