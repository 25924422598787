import React from "react";

const CashKarmaConfirmationIcon = () => {
  return (
    <svg
    width="60px"
    height="60px"
    viewBox="0 0 137 137"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>{"icons8-success"}</title>
    <g
      id="MintKarma-Assets"
      stroke="none"
      strokeWidth={1}
      fill="none"
      fillRule="evenodd"
    >
      <g id="icons8-success" transform="translate(5, 5)">
        <circle
          id="Oval"
          fill="#5DCC83"
          fillRule="nonzero"
          cx={73.1527094}
          cy={73.1527094}
          r={58.8472906}
        />
        <path
          d="M93.635468,110.216749 C84.2068966,116.719212 72.5024631,120.295567 60.1477833,120.295567 C26.9852217,120.295567 0,93.3103448 0,60.1477833 C0,45.8423645 5.20197044,32.5123153 13.3300493,22.1083744"
          id="Path"
          stroke="#15120E"
          strokeWidth={9.75369458}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M47.4679803,1.30049261 C51.6945813,0.325123153 55.9211823,0 60.1477833,0 C93.3103448,0 120.295567,26.9852217 120.295567,60.1477833 C120.295567,69.5763547 118.019704,78.3546798 114.44335,86.1576355"
          id="Path"
          stroke="#15120E"
          strokeWidth={9.75369458}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <polyline
          id="Path"
          stroke="#15120E"
          strokeWidth={9.75369458}
          strokeLinecap="round"
          strokeLinejoin="round"
          points="35.7635468 61.773399 52.0197044 78.0295567 84.5320197 45.5172414"
        />
      </g>
    </g>
  </svg>
  );
};

export default CashKarmaConfirmationIcon;
