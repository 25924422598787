import { Image } from '@nextui-org/react'
import React from 'react'

import DividerIcon from '../Images/MomoKarmaImages/FooterMiddleDot.svg'
export default function Footer() {
  return (
    <div className='bg-[#147AFF] absolute bottom-0 left-0 z-40 w-screen px-4 md:py-5 py-3'>
      <div className='lg:px-10 px-2 text-white lg:container mx-auto lg:text-base text-sm flex md:flex-row flex-col justify-between font-base' style={{ fontFamily: 'Plus Jakarta Sans',fontWeight: '400' }}>
        <div className='px-4 text-center md:text-left'>© 2025 MomoKarma, All Rights Reserved</div>
        <div className='flex justify-center md:justify-start w-full md:w-auto items-center md:gap-4 gap-2 md:mt-0 mt-2 px-2'>
          <span><a className='cursor-pointer' href='/legal/privacy'>Privacy Policy</a></span>
          <Image
            src={DividerIcon}
            alt="Divider logo"
            width={'4'}
            height={'4'}
          />
          <span><a className='cursor-pointer' href='/legal/terms'>Terms of Service</a></span>
        </div>
      </div>
    </div>

  )
}
